import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import GhostHead from "components/ghost/GhostHead";
import { Layout } from "../components";
import Content from "../components/Content";

/* eslint-disable react/prop-types */
export const Head = ({ data, location }) => {
    const metaProps = { data, location, type: "website" };
    const page = data.ghostPage;
    return (
        <>
            <style type="text/css">{`${page.codeinjection_styles}`}</style>
            <GhostHead metaProps={metaProps} />
        </>
    );
};
/* eslint-enable react/prop-types */

/**
 * Single page (/:slug)
 *
 * This file renders a single page and loads all the content.
 *
 * @param root0
 * @param root0.data
 * @param root0.location
 */

const Page = ({ data }) => {
    const page = data.ghostPage;
    return (
        <Layout>
            <main className="site-main">
                <Content data={page} html={page.html} />
            </main>
        </Layout>
    );
};

Page.propTypes = {
    data: PropTypes.shape({
        ghostPage: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
};

export default Page;

export const postQuery = graphql`
    query ($slug: String!) {
        ghostPage(slug: { eq: $slug }) {
            feature_image_caption
            ...GhostPageFields
        }
    }
`;
